@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Nunito Sans",sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


._bg-gray-lighten-more {
  background-color: #f2f4f8;
}

.primary-btn-base {
  background-color:  #0190fe;
  border: thin solid #0190fe;
}

